export enum index_map {
  Twitter = "twitter",
  Discord = "discord",
  Podcast = "podcast",
  Mirror = "mirror",
  DuneDashboard = "dune_dashboard",
  Youtube = "youtube",
  Twitter_Space = "twitter_space",
  Medium = "medium",
  Whitepaper = "whitepaper",
  "Forum" = "forum",
  "News" = "news",
  "Research" = "research",
  "SnapShot" = "snapshot_proposal",
  "Newsletter" = "newsletter",
  "Warpcast" = "farcaster",
  Farcaster = "farcaster",
  "Telegram" = "telegram_message",
  "Tally" = "tally_proposal",
}

export enum TallySupports {
  "FOR",
  "AGAINST",
  "ABSTAIN",
}
